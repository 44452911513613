require(['jquery', 'matchHeight'], function ($, matchHeight) {
  function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return false;
  }
  var codeUid = getQueryVariable("uid");

  //Ajouter le lien personalisé dans le call to action seulement lorsqu'il y a un uid en param url
  if (codeUid != false) {
    var paramUid = "?uid=" + codeUid;
    var urlLienPersonnalise = $(".call-to-action-lien-personnalise a").attr("href") + paramUid;
    $(".call-to-action-lien-personnalise a").attr("href", urlLienPersonnalise);
  }
});